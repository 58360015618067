var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2e02b57fa5774552f6ca8e9d6f46acd1249a927b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/nextjs');

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
});
